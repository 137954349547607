.inputStyle {
  height: 2rem;
  font-size: 0.9rem !important;
}

.inputStyle,
input::placeholder {
  font-size: 0.8rem;
}

.labelStyle {
  font-size: 0.8rem;
  margin-bottom: 0;
  margin-top: 0;
}

.errorStyle {
  color: red;
  font-size: 0.7rem;
}

.saveButton {
  width: 10%;
  height: 35px;
  background-color: #4549a2;
  color: white;
  font-size: 15px;
  margin-top: 3%;
  border-radius: 10px;
}

.deleteButton {
  width: 8%;
  height: 35px;
  background-color: #636678;
  color: white;
  font-size: 15px;
  margin-top: 3%;
  margin-left: 2%;
  border-radius: 10px;
}

.employeeRoles {
  border: 1px solid #e9e9ef;
  padding-top: 0.5%;
  margin-bottom: 1%;
  padding-bottom: 1%;
  padding-left: 0.5%;
  padding-right: 0.5%;
}

.employeeRoles_labelStyle_arrow {
  color: #4549a2;
}

.employeeRoles_labelStyle {
  font-size: 0.8rem;
  margin-bottom: 0;
}

.selectStyle {
  width: 100%;
  height: 2rem;
  font-size: 0.7rem;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.selectorContainer {
  width: 100%;
}

.addButton {
  width: fit-content;
  height: 2rem;
  background-color: #4549a2;
  color: white;
  padding-left: 2%;
  padding-right: 2%;
  font-size: 0.9rem;
  border-radius: 10px;
}

.headerCell {
  font-size: 12px;
  width: fit-content;
  text-align: center;
  padding: 0;
  padding-block: 1.5%;
  background-color: #f0f2f5;
}

.pStyles {
  margin: 0;
  padding-block: 2%;
}

.dataCell {
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  align-items: center;
}

.dataCellRemaining {
  font-size: 11px;
  padding: 0;
  padding-block: 15%;
  font-weight: bold;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

.dataCellRemainingAssigned {
  font-size: 11px;
  padding: 0;
  font-weight: bold;
  text-align: center;
  justify-content: space-between;
  align-items: center;
}

.statusDataCell {
  font-size: 11px;
  text-align: center;
  align-items: center;
  color: #8286f0;
}

.tableDeleteButton {
  color: red;
  font-size: 11px;
}

.progress {
  color: #f62d37;
}

.progressContainer {
  display: flex;
  height: 150px;
  justify-content: center;
  align-items: center;
}

.inProgressStyle {
  background-color: #e0f2f1;
}

.completedStyle {
  text-decoration: line-through;
}

.high {
  color: red;
  font-weight: bold;
}

.low {
  color: green;
}

.medium {
  color: #ff9800;
}

.reportContainer {
  /* background-color: white; */
  padding: 2%;
}

.reportTitle {
  margin-bottom: 3%;
  font-weight: bold;
  font-family: tahoma;
}

.alertContainerStyle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alertStyle {
  width: 1000px;
  justify-content: center;
}

.addIconStyle {
  margin-right: 3%;
  color: white;
}

.tableContainer {
  margin-top: 2%;
}

.tableHeadStyle {
  background-color: #f0f2f5;
}

.buttonsContainer {
  font-size: 11px;
}

.editButton {
  font-size: 11px;
}

.pageTitle {
  font-family: sans-serif;
  font-weight: 600;
  font-size: x-large;
  margin-top: 0;
  padding: 0;
  margin: 0;
  color: #1b2d55;
}

.formTitle {
  font-family: sans-serif;
  font-weight: 600;
  font-size: large;
  margin-top: 0;
  padding: 0;
  margin: 0;
  color: #1b2d55;
}

.subPageTitle {
  color: #828385;
  font-size: small;
  padding: 0;
  margin: 0;
  margin-bottom: 2%;
}

.tab {
  padding: 0;
  margin: 0;
  /* background-color: red; */
}

.teamTabHeader {
  width: fit-content;
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 1.2rem;
  color: #9c27b0;
}
.myTabHeader {
  width: fit-content;
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 1.3rem;
  color: #1976d2;
  font-family: Tahoma;
}
.projectsCounter {
  font-family: sans-serif;
  font-weight: 800;
  font-size: xx-large;
  margin-top: 0;
  margin-bottom: 3%;
  margin-left: 1%;
  color: #1976d2;
}

.middlePersentageDataCell {
  font-size: 11px;
  text-align: center;
  color: #f1a115;
  background-color: #fcf0dd;
  padding-block: 8%;
  padding-inline: 10%;
  border-radius: 6px;
}

.formContainer {
  display: flex;
  justify-content: center;
  padding-top: 2%;
}

.approvedStatus {
  font-size: 11px;
  text-align: center;
  color: white;
  background-color: #12633f;
  padding-inline: 5%;
  padding-block: 2%;
  border-radius: 10px;
  width: max-content;
}

.newStatus {
  font-size: 11px;
  text-align: center;
  color: white;
  background-color: #6b97ff;
  padding-inline: 5%;
  padding-block: 2%;
  border-radius: 10px;
  width: max-content;
}

.notAssignedStatus {
  font-size: 11px;
  text-align: center;
  color: #ffffff;
  background-color: #f01900;
  padding-inline: 5%;
  padding-block: 2%;
  border-radius: 10px;
  width: max-content;
}

.notTaken {
  font-size: 11px;
  text-align: center;
  color: #f01900;
  background-color: #ffe8e5;
  padding-inline: 5%;
  padding-block: 2%;
  border-radius: 10px;
  width: max-content;
}

.inProgressStatus {
  font-size: 11px;
  text-align: center;
  color: #f1a115;
  background-color: #fcf0dd;
  padding-inline: 5%;
  padding-block: 2%;
  border-radius: 10px;
  width: max-content;
}

.toBdStatus {
  font-size: 11px;
  text-align: center;
  color: #9670ff;
  display: flex;
  align-items: center;
  background-color: #f3e5f5;
  padding-block: 6%;
  padding-inline: 6%;
  border-radius: 8px;
  width: fit-content;
}

.completedStatus {
  font-size: 11px;
  text-align: center;
  color: #00beaa;
  display: flex;
  align-items: center;
  background-color: #d6fdf9;
  padding-block: 6%;
  padding-inline: 4%;
  border-radius: 8px;
  width: fit-content;
}

.checkIcon {
  font-size: large;
  margin-right: 1%;
}

.addUpdateIcon {
  cursor: pointer;
}

.addUpdateInputBox {
  width: 60%;
  margin-top: 20px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 10px;
  font-size: 20px;
}

.updatesListStyle {
  display: flex;
  justify-content: center;
}

.updatesPageTitle {
  color: #1b2d55;
  font-weight: 600;
  font-size: x-large;
  padding: 0;
  margin: 0;
}

.updatesPageSubTitle {
  color: #959485;
  font-size: smaller;
}

.listItemStyle {
  margin-block: 10px;
  border: 1px solid #dedfe1;
  border-radius: 10px;
}

.fixedlistItemStyle {
  margin-block: 10px;
  background-color: #eaf6ff;
  border: 1px solid #bbdefb;
  border-radius: 10px;
}

.updatesContainer {
  padding: 30px;
  background-color: white;
}

.secondaryAction {
  display: none;
}
.backButton {
  background-color: #636678;
  width: 7%;
  height: 2rem;
  color: white;
  font-size: 0.9rem;
  border-radius: 10px;
  margin-bottom: 2%;
}
.addIconStyle {
  margin-right: 3%;
  color: white;
}
