.inputStyle {
  height: 2rem;
  font-size: 0.7rem;
}

.labelStyle {
  font-size: 0.8rem;
  margin-bottom: 0;
}
.errorStyle {
  color: red;
  font-size: 0.7rem;
}
 
.saveButton {
  width: 10%;
  height: 35px;
  background-image: linear-gradient(to right, #4facfe 40%, #02d1dc 100%);
  color: white;
  font-size: 15px;
  margin-top: 3%;
  border-radius: 10px;
}

.deleteButton {
  width: 8%;
  height: 35px;
  background-image: linear-gradient(120deg, #f093fb 0%, #f5576c 100%);
  color: white;
  font-size: 15px;
  margin-top: 3%;
  margin-left: 2%;
  border-radius: 10px;
}

.selectStyle {
  width: 78%;
  margin-left: 3%;
  height: 2rem;
  font-size: 0.7rem;
}

.selectorContainer {
  margin-top: 2%;
  width: 100%;
}

.addButton {
  width: 10%;
  height: 2rem;
  background-color: #4549a2;
  color: white;
  font-size: 0.9rem;
  border-radius: 10px;
}
.backButton {
  background-color: #636678;
  width: 7%;
  height: 2rem;
  color: white;
  font-size: 0.9rem;
  border-radius: 10px;
}
.headerCell {
  font-size: 12px;
  width: fit-content;
  text-align: center;
  margin: 0;
  padding: 0;
  padding-block: 1.5%;
  background-color: #f0f2f5;
}

.headerCellFun {
  font-size: 12px;
  /* text-align: center; */
  background-color: #f0f2f5;
}

.filledheaderCell {
  font-size: 12px;
  font-weight: bold;
  width: fit-content;
  text-align: center;
  margin: 0;
  padding: 0;
  padding-block: 1.5%;
  background-color: #bbdefb;
}

.dataCell {
  font-size: 11px;
  text-align: center;
  width: fit-content;
  padding-block: 1.5%;
  font-weight: bold;
  padding-inline: 0;
}

.filleddataCell {
  font-size: 12px;
  text-align: center;
  width: fit-content;
  padding-block: 1.5%;
  font-weight: bold;
  padding-inline: 0;
}

.emptydataCell{
  font-size: 11px;
  text-align: center;
  width: fit-content;
  padding-block: 1.5%;
  font-weight: bold;
  padding-inline: 0;
  background-color: #f8bbd0;
}

.lessPersentageDataCell{
  font-size: 11px;
  text-align: center;
  color: red;
  background-color: #FFE3E2;
  padding: 2%;
  padding-inline: 3%;
  border-radius: 6px;
}

.middlePersentageDataCell{
  font-size: 11px;
  text-align: center;
  color: #f1a115;
  background-color: #fcf0dd;
  padding: 2%;
  padding-inline: 3%;
  border-radius: 6px;
}

.bigPersentageDataCell{
  font-size: 11px;
  text-align: center;
  color: #2AB57D;
  background-color: #d9f2e8;
  padding: 2%;
  padding-inline: 3%;
  border-radius: 6px;
}

.tableDeleteButton {
  color: red;
  font-size: 11px;
}
.progress {
  color: #f62d37;
}
.progressContainer {
  display: flex;
  height: 150px;
  justify-content: center;
  align-items: center;
}

.inProgressStyle {
  background-color: #e0f2f1;
}

.completedStyle {
  text-decoration: line-through;
}

.high {
  color: red;
  font-weight: bold;
}

.low {
  color: green;
}

.medium {
  color: #ff9800;
}

.reportContainer {
  /* background-color: white; */
  padding: 2%;
}
.pageTitle{
  font-family: sans-serif;
  font-weight: 600;
  font-size: x-large;
  margin-top: 0;
  margin-bottom: 3%;
  color: #1B2D55;
}

.emptyDaysPageTitle{
  font-family: sans-serif;
  font-weight: 600;
  font-size: large;
  margin-top: 1%;
  margin-right: 2%;
  color: #1B2D55;
}

.reportTitle {
  margin-bottom: 3%;
  font-weight: bold;
  font-family: tahoma;
}
.alertContainerStyle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.alertStyle {
  width: 1000px;
  justify-content: center;
}
.addIconStyle {
  margin-right: 3%;

  color: white;
}
.tableContainer {
  margin-top: 2%;
}
.tableHeadStyle {
  background-color: #f0f2f5;

}
.memberTableHeadStyle {
  background-color: #f0f2f5;
}

.buttonsContainer {
  font-size: 11px;
  padding: 0.2;
  margin: 0;
}

.editMonthlyButton{
  width: fit-content;
  padding: 0;
  padding-inline: 3%;
  margin: 0;
  margin-right: 2%;
}

.editButton {
  font-size: 12px;
  margin: 0;
  width: fit-content;
  padding: 0;
}
.monthlyReportButtons{
  font-size: 12px;
  margin: 0;
  margin-inline: 2%;
  width: fit-content;
  padding: 0;
  padding-inline: 1%;
}

.reportButton {
  font-size: 12px;
  margin: 0;
  padding: 0;
  padding-inline: 10%;
  padding-block: 1%;
}
