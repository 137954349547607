* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.email {
  border:1px solid #ada9a9;
  margin-bottom: 10px;
}
.email:focus {
  outline: none !important;
  border: 1px solid #719ece;
  box-shadow: 0 0 10px #719ece;
}

.image {
  margin-left: auto;
  margin-right: auto;
  object-fit: fill;
  width: 100%;
  height: 100%;
  filter: brightness(50%);
}
.labelStyle {
  font-size: 1rem;
  margin-bottom: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.logoStyle {
  width: 33%;
}
.subLogoStyle {
font-size: 1.5rem;
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  right: 0;
  top: 0;
  margin-top: 1%;
  margin-right: 1.5%;
  position: absolute;
}
