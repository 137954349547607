.profileContainer {
}
.userNameStyle {
  color: black;
  font-weight: bold;
  font-size: 0.9rem;
  margin-right: 4%;
}
.profileIcon {
  margin-right: 10px;
  font-size: 0.8rem;

}
.profileIcon:hover{
  border-color: white !important;
}

.myProfileStyle {
  font-size: 0.8rem;
}

.profileButton{
background-color: #FBFAFF;
width: fit-content;
padding: 0;
}
.menue{
  margin-top: 1%;
}
.menueStyle{

}

.userName{}

.user_avatar{display: none;}

@media only screen and (max-width : 600px) {
  .menue{
    margin-top: 2%;
  }
  .userName{ display: none;}
  .user_avatar{display: block;}
}