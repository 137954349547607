.avatar {
  width: 46;
  height: 46;
  margin-right: 5%;
  border: 2px solid #74788D;
}
.ProfilePageAvatar{
  width: 70px;
  height: 69px;
  margin-right: 1.5%;
}

.avatarChart{
  width: 60px;
  height: 60px;
  object-fit: cover;
  object-position: center left;
}

.avatarChartCeo{
  width: 70px;
  height: 70px;
  /* border-radius: 50%; */

  object-fit: cover;
  object-position: center right;
}