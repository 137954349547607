/* @collapse */

.inputStyle {
  height: 2rem;
  font-size: 0.9rem !important;
}

.inputStyle,
input::placeholder {
  font-size: 0.8rem;
}

.labelStyle {
  font-size: 0.8rem;
  margin-bottom: 0;
  margin-top: 0;
}

.errorStyle {
  color: red;
  font-size: 0.7rem;
}

.saveButton {
  width: 10%;
  height: 35px;
  background-color: #4549a2;
  color: white;
  font-size: 15px;
  margin-top: 3%;
  border-radius: 10px;
}

.addRowButton {
  width: 10%;
  height: 35px;
  background-color: #8286f0;
  color: white;
  font-size: 15px;
  border-radius: 10px;
}
.addRowButtonContainer {
  display: flex;
  justify-content: right;
  padding-top: 1%;
}

.deleteButton {
  width: 8%;
  height: 35px;
  background-color: #636678;
  color: white;
  font-size: 15px;
  margin-top: 3%;
  margin-left: 2%;
  border-radius: 10px;
}

.employeeRoles {
  border: 1px solid #e9e9ef;
  padding-top: 0.5%;
  margin-bottom: 1%;
  padding-bottom: 1%;
  padding-left: 0.5%;
  padding-right: 0.5%;
}

.employeeRoles_labelStyle_arrow {
  color: #4549a2;
}

.employeeRoles_labelStyle {
  font-size: 0.8rem;
  margin-bottom: 0;
}

.selectStyle {
  width: 100%;
  height: 2rem;
  font-size: 0.7rem;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.selectStyleError {
  width: 100%;
  height: 2rem;
  font-size: 0.7rem;
  border: 1px solid red;
  border-radius: 5px;
}

.selectorContainer {
  width: 100%;
}

.addButton {
  width: fit-content;
  height: 2rem;
  background-color: #4549a2;
  color: white;
  padding-left: 2%;
  padding-right: 2%;
  font-size: 0.9rem;
  border-radius: 10px;
}

.headerCell {
  font-size: 12px;
  width: fit-content;
  text-align: center;
  padding: 0;
  padding-block: 1.5%;
  background-color: #f0f2f5;
}
.pStyles {
  margin: 0;
  padding-block: 2%;
}
.dataCell {
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  align-items: center;
}

.planner {
  background-color: #f2dcdb;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  align-items: center;
}
.planner_group {
  background-color: #f2dcdb;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  align-items: center;
}
.technical {
  background-color: #fff2cc;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  align-items: center;
}
.technical_group {
  background-color: #fff2cc;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  align-items: center;
}
.submission {
  background-color: #e2efda;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  align-items: center;
}
.submission_group {
  background-color: #e2efda;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  align-items: center;
}
.notes {
  background-color: #daeef3;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  align-items: center;
}
.notes_group {
  background-color: #daeef3;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  align-items: center;
}
.calculations {
  background-color: #ead5ff;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  align-items: center;
}
.calculations_group {
  background-color: #ead5ff;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  align-items: center;
}

.reminders {
  background-color: #d9d9d9;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  align-items: center;
}
.reminders_group {
  background-color: #d9d9d9;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  align-items: center;
}

.dataCellRemaining {
  font-size: 11px;
  padding: 0;
  padding-block: 15%;
  font-weight: bold;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

.dataCellRemainingAssigned {
  font-size: 11px;
  padding: 0;
  font-weight: bold;
  text-align: center;
  justify-content: space-between;
  align-items: center;
}

.statusDataCell {
  font-size: 11px;
  text-align: center;
  align-items: center;
  color: #8286f0;
}

.tableDeleteButton {
  color: red;
  font-size: 11px;
}

.progress {
  color: #f62d37;
}

.progressContainer {
  display: flex;
  height: 150px;
  justify-content: center;
  align-items: center;
}

.inProgressStyle {
  background-color: #e0f2f1;
}

.completedStyle {
  text-decoration: line-through;
}

.high {
  color: red;
  font-weight: bold;
}

.low {
  color: green;
}

.medium {
  color: #ff9800;
}

.reportContainer {
  /* background-color: white; */
  padding: 2%;
}

.reportTitle {
  margin-bottom: 3%;
  font-weight: bold;
  font-family: tahoma;
}

.alertContainerStyle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alertStyle {
  width: 1000px;
  justify-content: center;
}

.addIconStyle {
  margin-right: 3%;
  color: white;
}

.tableContainer {
  margin-top: 2%;
}

.tableHeadStyle {
  background-color: #f0f2f5;
}

.buttonsContainer {
  font-size: 11px;
}

.editButton {
  font-size: 11px;
}
.pageTitle {
  font-family: sans-serif;
  font-weight: 600;
  font-size: x-large;
  margin-top: 0;
  margin-bottom: 3%;
  color: #1b2d55;
}

.projectsCounter {
  font-family: sans-serif;
  font-weight: 800;
  font-size: xx-large;
  margin-top: 0;
  margin-bottom: 3%;
  margin-left: 1%;
  color: #1976d2;
}

.middlePersentageDataCell {
  font-size: 11px;
  text-align: center;
  color: #f1a115;
  background-color: #fcf0dd;
  padding-block: 8%;
  padding-inline: 10%;
  border-radius: 6px;
}
.newStatus {
  font-size: 11px;
  text-align: center;
  color: white;
  background-color: #6b97ff;
  padding-block: 6%;
  padding-inline: 10%;
  border-radius: 8px;
  width: fit-content;
}
.notAssignedStatus {
  font-size: 11px;
  text-align: center;
  color: #f01900;
  background-color: #ffe8e5;
  padding-block: 4%;
  padding-inline: 6%;
  border-radius: 8px;
  width: fit-content;
}

.inProgressStatus {
  font-size: 11px;
  text-align: center;
  color: #f1a115;
  display: flex;
  align-items: center;
  background-color: #fcf0dd;
  padding-block: 6%;
  padding-inline: 6%;
  border-radius: 8px;
  width: fit-content;
}

.toBdStatus {
  font-size: 11px;
  text-align: center;
  color: #9670ff;
  display: flex;
  align-items: center;
  background-color: #f3e5f5;
  padding-block: 6%;
  padding-inline: 6%;
  border-radius: 8px;
  width: fit-content;
}
.completedStatus {
  font-size: 11px;
  text-align: center;
  color: #00beaa;
  display: flex;
  align-items: center;
  background-color: #d6fdf9;
  padding-block: 6%;
  padding-inline: 4%;
  border-radius: 8px;
  width: fit-content;
}
.checkIcon {
  font-size: large;
  margin-right: 1%;
}
.fakeDev {
  height: 500;
  min-width: 650;
  width: 100%;
  margin-top: 2%;
  background-color: white;
}
.singleSelect {
  padding: 0;
  margin: 0;
}

.updatesitem {
  height: 2rem;
  font-size: 0.9rem;
  margin-inline: 3px;
  min-width: fit-content;
}
.updatesitemNotes {
  height: 2rem;
  font-size: 0.9rem;
  margin-inline: 3px;
  width: 25%;
  min-width: fit-content;
}
.updatesitem_DLUp {
  height: 2rem;
  font-size: 0.9rem;
  margin-inline: 3px;
  min-width: 90px;
  max-width: 30px;
}
.updatesContainer {
  display: flex;
  padding-block: 2%;
  overflow-x: auto;
  white-space: nowrap;
}

@keyframes myAnimation {
  0% {
    background-color: #ef9a9a;
  }
  50% {
    background-color: red;
    color: white;
  }
  100% {
    background-color: red;
    color: white;
  }
}

.animation {
  background-color: red;
  animation-name: myAnimation;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.projectStatgeItems_header {
  padding: 2px;
  margin: 0;
  font-weight: 600;
  color: #1a237e;
}

.projectStatgeItems_header_Remainder {
  padding: 2px;
  margin: 0;
  font-weight: 600;
  color: red;
}
.projectStatgeItems_sub {
  padding: 2px;
  margin: 0;
}
