.summaryContainer {
  background-color: white;
  padding: 2%;

  display: flex;
  justify-content: space-between;
}
.tableContainer {
  margin-top: 2%;
  width: 45%;
  height: 90%;
}
.tableStyle {
  width: 100%;
  height: 100%;
}
.tableHeadStyle {
  background-color: #f0f2f5;
  height: 1%;
}
