.leftDiv {
  border: 1px solid #e9e9ef;
  padding: 1%;
  background-color: white;
}
.leftDivTabs {
    border: 1px solid #e9e9ef;
    padding: 1%;
    margin-top: 3%;
    background-color: white;
  }
.rightDiv {
  border: 1px solid #e9e9ef;
  padding: 2%;
  background-color: white;
}
.rightDivProtfolio{
    border: 1px solid #e9e9ef;
    padding: 2%;
    margin-top: 5%;
    background-color: white;
}
.title {
  margin: 1%;
  margin-left: 0.7%;
}
.firstBlock {
  display: flex;
  align-items: center;
}
.userName {
  font-size: 1rem;
  font-weight: bold;
  color: #495057;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.userJobTitle {
  font-size: 0.9rem;
  color: #495057;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.userDepartment {
  font-size: 0.9rem;
  color: #495057;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.dotIcon{
    color: #2AB57D;
    font-size: 0.3rem;
    margin-right: 6%;
}
.skillsTextStyle{
    background-color: #dadbf5;
    color: #5156BE;
    margin-right: 2%;
    border-radius: 6px;
    padding: 0.4%;
    font-size: 0.7rem;
    display: inline-block;
}
.anchorTag{
    font-size: 0.9rem;
    color: #495057;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    text-decoration-line: none;
}
.icon{
    font-size: 1.1rem;
    color: #5156BE;
}
.iconContainer{
    margin-top: 2% !important ;
}