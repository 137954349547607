.notificationStyle {
  font-size: 1.3rem;
  position: relative;
}
.notification_counter {
  background-color: #fd625e;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 0.8rem;
  position: absolute;
  top: 0;
  right: 0;
}
.notificationButton {
  height: 3rem;
  width: 2.9rem;
}
.menue {
  margin-top: 0.8%;
}
.arrowStyle {
  color: #5156be !important;
}
.viewAllSpan {
  color: #5156be;
}
.notificationsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2%;
}
.notificationDivider {
  background-color: #e9e9ef;
}
.notificationSpan {
  color: #495057;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  margin-bottom: 1%;
}
.viewAllButton {
  color: #5156be;
  font-size: 0.8rem;
}
.listItemContainer {
  display: flex;
  flex-direction: column;
  margin-left: 1%;
}
.messageSpan {
  color: #828385;
}

.senderSpan{
  color: #495057;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.noNotificationsYet{
  display: flex;
  justify-content: center;
  margin-top: 4%;
  margin-bottom: 4%;
}