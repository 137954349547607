.inputStyle {
  height: 2rem;
  font-size: 0.9rem !important;
  /* margin-bottom: 2%; */
}

.inputStyle,
input::placeholder {
  font-size: 0.8rem;
}

.labelStyle {
  font-size: 0.8rem;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
  margin: 0;
  font-weight: 600;
}
.errorStyle {
  color: red;
  font-size: 0.7rem;
}

.formContainer {
  margin-inline: 3%;
  border: 1px solid #e9e9ef;
  padding: 2%;
  background-color: white;
}

.saveButton {
  width: 10%;
  height: 35px;
  background-color: #4549a2;
  color: white;
  font-size: 15px;
  margin-top: 3%;
  border-radius: 10px;
}

.deleteButton {
  width: 8%;
  height: 35px;
  background-color: #636678;
  color: white;
  font-size: 15px;
  margin-top: 3%;
  margin-left: 2%;
  border-radius: 10px;
}

.employeeRoles {
  border: 1px solid #e9e9ef;
  padding-top: 0.5%;
  padding-bottom: 1%;
  padding-left: 0.5%;
  padding-right: 0.5%;
}

.employeeRoles_labelStyle_arrow {
  color: #4549a2;
}

.employeeRoles_labelStyle {
  font-size: 0.8rem;
  margin-bottom: 0;
  margin-top: 1%;
}

.selectStyle {
  width: 100%;
  height: 2rem;
  font-size: 0.7rem;
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 0;
  margin: 0;
  /* margin-bottom: 3%; */
}

.selectorContainer {
  width: 100%;
  margin-bottom: 2%;
  align-items: center;
}

.addButton {
  width: 7%;
  height: 2rem;
  background-color: #4549a2;
  color: white;
  font-size: 0.9rem;
  border-radius: 10px;
}
.headerCell {
  font-size: 12px;
  text-align: center;
}
.dataCell {
  font-size: 11px;
  text-align: center;
}
.tableDeleteButton {
  color: red;
  font-size: 11px;
}
.progress {
  color: #f62d37;
}
.progressContainer {
  display: flex;
  height: 150px;
  justify-content: center;
  align-items: center;
}

.inProgressStyle {
  background-color: #e0f2f1;
}

.completedStyle {
  text-decoration: line-through;
}

.high {
  color: red;
  font-weight: bold;
}

.low {
  color: green;
}

.medium {
  color: #ff9800;
}

.reportContainer {
  background-color: white;
  padding: 2%;
}
.reportTitle {
  margin-bottom: 3%;
  font-weight: bold;
  font-family: tahoma;
}
.alertContainerStyle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.alertStyle {
  width: 1000px;
  justify-content: center;
}
.addIconStyle {
  margin-right: 3%;
  color: white;
}
.tableContainer {
  margin-top: 2%;
}
.tableHeadStyle {
  background-color: #f0f2f5;
}
.buttonsContainer {
  font-size: 11px;
}
.editButton {
  font-size: 11px;
}
.dividerStyle {
  border: 1px solid black;
  margin-inline: 30px;
}
.hideDivider {
  display: none;
}
.subcheckbox {
  font-size: 0.3rem;
}
.autocomplete {
  display: flex;
  justify-content: center;
  width: 49.5%;
}
