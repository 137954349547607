.inputStyle {
  height: 2rem;
  font-size: 0.7rem;
}

.labelStyle {
  font-size: 0.8rem;
  margin-bottom: 0;
}
.errorStyle {
  color: red;
  font-size: 0.7rem;
}
  
.saveButton {
  width: 10%;
  height: 35px;
  background-color:#4549a2;
  color: white;
  font-size: 15px;
  margin-top: 3%;
  border-radius: 10px;
}

.deleteButton {
  width: 8%;
  height: 35px;
  background-image: linear-gradient(120deg, #f093fb 0%, #f5576c 100%);
  color: white;
  font-size: 15px;
  margin-top: 3%;
  margin-left: 2%;
  border-radius: 10px;
}

.selectStyle {
  width: 78%;
  margin-left: 3%;
  height: 2rem;
  font-size: 0.7rem;
}

.selectorContainer {
  margin-top: 2%;
  width: 100%;
}

.addButton {
  width: 7%;
  height: 2rem;
  background-color:#4549a2;
  color: white;
  font-size: 0.9rem;
  border-radius: 10px;
}
.backButton {
  background-color: #636678;
  width: 7%;
  height: 2rem;
  color: white;
  font-size: 0.9rem;
  border-radius: 10px;
}
.headerCell {
  font-size: 12px;
  text-align: center;
  background-color: #f0f2f5;
  width: fit-content;
  margin: 0;
  padding-block: 1.5%;
}
.dataCell {
  font-size: 11px;
  text-align: center;
  width: fit-content;
  padding-block: 1.5%;
}
.tableDeleteButton {
  color: red;
  font-size: 11px;
}
.progress {
  color: #f62d37;
}
.summaryContainer {
  background-color: white;
  padding: 2%;
  height: 80vh;
  display: flex;
  justify-content: space-between;
}
.progressContainer {
  display: flex;
  height: 150px;
  justify-content: center;
  align-items: center;
}

.inProgressStyle {
  background-color: #e0f2f1;
}

.completedStyle {
  text-decoration: line-through;
}

.high {
  color: red;
  font-weight: bold;
}

.low {
  color: green;
}

.medium {
  color: #ff9800;
}


.reportContainer {
  background-color: white;
  padding: 2%;
}
.reportTitle {
  margin-bottom: 3%;
  font-weight: bold;
  font-family: tahoma;
}
.alertContainerStyle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.alertStyle {
  width: 1000px;
  justify-content: center;
}
.addIconStyle {
  margin-right: 3%;
  color: white;
}
.tableContainer{
  margin-top: 2%
}
.tableHeadStyle{
  background-color: #F0F2F5
}
.buttonsContainer{
  font-size: 11px;
}
.editButton{
  font-size: 11px;
}