.appbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 20px;
}

.drawerHeader {
  min-width: 20%;
  align-items: center;
  display: flex;
}
.profileDiv {
  background-color: azure;
  width: 9%;
  min-height: 100%;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  min-width: fit-content;
  background-color: #fbfaff;
}

.profileSmallContainer {
  display: flex;
}
.notificationStyle {
  font-size: 1.3rem;
  position: relative;
}
.notification_counter {
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 15px;
  height: 20px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
}

.myTeamTab{
  display: none;
}

.userAvatar_container {
  margin-right: 2%;
}

.sideParIcon{
  font-size: large;
}
.drawerTitle {
  margin-right: 50%;
  margin-top: 5%;
  color: white;
}
.subLogoStyle {
  width: 70%;
}

/* sidebar */
.sideBarIcon {
  font-size: 1.3rem;
}

.listItem {
  display: "block";
}

.listItemText {
  font-size: 1.4rem;
  margin-left: 3px;
  color: white;
}

.listItemIcon {
  font-size: 1.4rem;
  color: white;
}

.expand_listItemIcon {
  font-size: 1.4rem;
  margin-left: 3px;
  color: white;
}
.listItemButtonClass{
  color: white;
}
.routeStyle{
  width: 100% ; 
  height: fit-content;
  display:flex;
  justify-content: left;
  margin-top: 0;
  margin-bottom: 2%;
  padding: 0;
}
.showProfile{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10%;
}
.profileTitle{
  font-weight: bold;
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 0;
  margin: 0;
}
.profilePosition{
  color: #359C81;
  font-size: 0.8rem;
  padding: 0;
  margin: 0;
}
.hideProfile{
  display: none;
}

.DailyTasksStyle{
  display: none;
}
.expand_selected_listItemIcon {
  color: #90B4FF;
  font-size: 1.4rem;
  margin-left: 3px;
}
.projectTab{
  display: block;
}
.projectTabCondition{
  display: none;
}
.selected_listItemText {
  font-weight: 100;
  color: #90B4FF;
}
.selected_listItemIcon {
  color: #90B4FF;
  font-size: 1.4rem;
}

@media only screen and (max-width: 600px) {
  .appbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 20px;
  }
  .profileDiv {
    background-color: azure;
    width: fit-content;
    min-height: 100%;
    display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
    background-color: #fbfaff;
  }
  .userAvatar_container {
    display: none;
  }
}
