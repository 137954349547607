.inputStyle {
  height: 2rem;
  font-size: 0.9rem !important;
  margin-bottom: 3%;
}

.inputStyle,
input::placeholder {
  font-size: 0.8rem;
}

.labelStyle {
  font-size: 0.8rem;
  margin-bottom: 0;
  font-weight: 600;
  margin-top: 0;
}
.errorStyle {
  color: red;
  font-size: 0.7rem;
}

.saveButton {
  width: fit-content;
  padding-left: 5%;
  padding-right: 5%;
  height: 35px;
  background-color: #4549a2;
  color: white;
  font-size: 1.2rem;
  margin-right: 2%;
  border-radius: 10px;
}
.editScoreButton {
  width: fit-content;
  padding: 0;
  margin: 0;
}

.editMonthlyButton {
  width: fit-content;
  padding: 0;
  padding-inline: 3%;
  margin: 0;
  margin-right: 2%;
}

.formContainer {
  margin-inline: 3%;
  border: 1px solid #e9e9ef;
  padding: 2%;
  background-color: white;
  zoom: 90%;
}

.closeButton {
  width: fit-content;
  padding-left: 2%;
  padding-right: 2%;
  height: 35px;
  background-color: #fd625e;
  color: white;
  font-size: 1.2rem;
  margin-top: 3%;
  border-radius: 10px;
}
.completedButton {
  background-color: #30b781;
  width: 50%;
  height: 35px;
  color: white;
  font-size: 1rem;
  margin-top: 3%;
  border-radius: 10px;
  padding-left: 1%;
  padding-right: 1%;
}
.deleteButton {
  width: fit-content;
  padding-left: 5%;
  padding-right: 5%;
  height: 35px;
  background-color: #636678;
  color: white;
  font-size: 1.2rem;
  border-radius: 10px;
}

.formButtonsContainer {
  margin-top: 2%;
}
.controllers {
  display: flex;
}

.buttonsContainer {
  margin-top: 2%;
}
.show {
  display: block;
  color: red;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  margin-right: 1%;
}
.hide {
  display: none;
}
.statusContainer {
  width: 100%;
  margin-top: 2%;
}
.employeeRoles {
  border: 1px solid #e9e9ef;
  padding-top: 0.5%;
  padding-bottom: 1%;
  padding-left: 0.5%;
  padding-right: 0.5%;
}

.marketingEmployeeRoles {
  border: 1px solid #5f64c07e;
  padding-top: 0.5%;
  padding-bottom: 1%;
  padding-left: 0.5%;
  padding-right: 0.5%;
}

.updatesTableContainer {
  /* border: 1px solid #5f64c07e; */
  padding-bottom: 2%;
}

.employeeRoles_labelStyle_arrow {
  color: #4549a2;
}

.employeeRoles_labelStyle {
  font-size: 0.8rem;
  margin-bottom: 0;
  margin-top: 1%;
}

.selectStyle {
  width: 100%;
  height: 2rem;
  font-size: 0.7rem;
  border: 1px solid #ced4da;
  border-radius: 5px;
  margin-bottom: 3%;
}

.selectorContainer {
  width: 100%;
}

.addButton {
  width: 7%;
  height: 2rem;
  background-image: linear-gradient(120deg, #f093fb 0%, #f5576c 100%);
  color: white;
  font-size: 0.9rem;
  border-radius: 10px;
}
.headerCell {
  font-size: 12px;
  text-align: center;
  margin: 0;
}
.dataCell {
  font-size: 11px;
  text-align: center;
}
.tableDeleteButton {
  color: red;
  font-size: 11px;
}
.progress {
  color: #f62d37;
}
.progressContainer {
  display: flex;
  height: 150px;
  justify-content: center;
  align-items: center;
}

.inProgressStyle {
  background-color: #e0f2f1;
}

.completedStyle {
  text-decoration: line-through;
}

.high {
  color: red;
  font-weight: bold;
}

.low {
  color: green;
}

.medium {
  color: #ff9800;
}

.reportContainer {
  background-color: white;
  padding: 2%;
}
.reportTitle {
  margin-bottom: 3%;
  font-weight: bold;
  font-family: tahoma;
}
.alertContainerStyle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.alertStyle {
  width: 1000px;
  justify-content: center;
}
.addIconStyle {
  margin-right: 3%;
  color: white;
}
.tableContainer {
  margin-top: 2%;
}
.tableHeadStyle {
  background-color: #f0f2f5;
}
.buttonsContainer {
  font-size: 11px;
}
.editButton {
  font-size: 11px;
}
.saveScoreButtonIcon {
  font-size: 1rem;
  margin-right: 5%;
}
.titlediv {
  background-color: #b0b3f7;
  font-weight: bold;
  padding: 0.4%;
  margin-top: 1.5%;
  margin-bottom: 1%;
}
.saveScoreButton {
  font-size: 1rem;
  background-color: #4549a2;
  width: 30%;
  margin-top: 3%;
  border-radius: 10px;
  color: white;
  padding: 1%;
}

.editOtherRequestForm {
  min-width: 40vh;
}
.dividerStyle {
  border: 1px solid black;
  margin-inline: 30px;
}
.hideDivider {
  display: none;
}
.subcheckbox {
  font-size: 0.3rem;
}
.updatesContainer {
  display: flex;
  padding-block: 2%;
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}
.updatesitem {
  height: 2rem;
  font-size: 0.9rem !important;
  margin-inline: 3px;
  min-width: 100px; 
}
.updatesitem,
input::placeholder {
  font-size: 0.8rem;
}

